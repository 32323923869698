/* You can add global styles to this file, and also import other style files */


// 


.bt-page-wrapper {
  // padding: 0.6em;
}
.bt-content-main {
  min-height: 100px;

}

.bt-max-width-320 {
  max-width: 320px;
  margin: 50px auto 20px auto;
}

.bt-max-width-1200 {
  max-width: 1200px;
  margin: 10px auto 10px auto;
}

@media only screen and (max-width: 576px) {
  .bt-form-label-placeholder {
    margin-top: -15px;
  }
}
@media only screen and (min-width: 577px) {
  .bt-form-label-placeholder {
    height: 30px;
  }
}

.nz-table-xs .ant-table.ant-table-small {
  .ant-table-thead > tr > th, 
  .ant-table-tbody > tr > td {
    padding: 4px;
  }
}

.nz-table-striped tbody tr:nth-child(2n) td {
  background-color: rgba(0,0,0,0.05);
}

.ant-table-thead > tr {
  > th.text-right {
    text-align: right;
  }
  > th.text-center {
    text-align: center;
  }
}



#app-root nz-descriptions.descriptions-xs  .ant-descriptions-item-label {
  padding: 4px 4px;
}

@media only screen and (max-width: 576px) {
  date-range-popup {
    .ant-picker-panels {
      display: block !important;
      // .ant-picker-panel {
      //   width: 90vv;
      //   float: left;

      // }
    }
  }


  // .ant-calendar-range {
  //   width: 276px;
  //   .ant-calendar-range-part {
  //     width: 100%;
  //   }
  //   .ant-calendar-range-right {
  //     float: left;
  //     border-top: 1px solid #e8e8e8;
  //   }
  // }
}

.ql-container {

  background: #fff;

}


/* order status */
.status-reservation 	{ background-color: yellow !important; color: black;					}
.status-waiting-list 	{ background-color: lightgreen !important; color: black;				}
.status-definitive 		{ background-color: green !important; 	color: white; 	}
.status-cancelled 		{ background-color: red !important; 	color: white; 	}
.status-long-term 		{ background-color: blue !important; 	color: white; 	}
.status-provider 		{ background-color: purple !important; 	color: white; 	}
.status-availability 	{ background-color: #dddddd !important; color: black;				}

.status-bg-reservation   { background-color: #f9f998 !important;  }
.status-bg-waiting-list  { background-color: #a5ff7f !important; }
.status-bg-definitive    { background-color: #DFF0D8 !important;   }
.status-bg-cancelled     { background-color: #efd7d7 !important;    }
.status-bg-long-term     { background-color: #a8abff !important;  }
.status-bg-provider    { background-color: #e4b7ff !important; }
.status-bg-availability  { background-color: #dddddd !important;   }



/* Colors */
.bgc-y, 	.bgc-yellow 		{ background-color: #FFDE17; } 	/* Gul */
.bgc-yo, 	.bgc-yellow-orange 	{ background-color: #F9A61C; } 	/* Gul-Orange */
.bgc-o, 	.bgc-orange 		{ background-color: #F68121; } 	/* Orange */
.bgc-or, 	.bgc-orange-red 	{ background-color: #EE3E3E; } 	/* Orange-Rød */
.bgc-r, 	.bgc-red 			{ background-color: #ED1C24; } 	/* Rød */
.bgc-rp, 	.bgc-red-purple 	{ background-color: #A3248F; } 	/* Rød-Lilla */
.bgc-p, 	.bgc-purple 		{ background-color: #5C2E91; } 	/* Lilla */
.bgc-pb, 	.bgc-purple-blue 	{ background-color: #21409B; } 	/* Lilla-Blå */
.bgc-b, 	.bgc-blue 			{ background-color: #0166B4; } 	/* Blå */
.bgc-bg, 	.bgc-blue-green 	{ background-color: #01AAAD; } 	/* Blå-Grønn */
.bgc-g, 	.bgc-green 			{ background-color: #00A560; } 	/* Grønn */
.bgc-gy, 	.bgc-green-yellow 	{ background-color: #70BE44; } 	/* Grønn-Gul */


.bgc-gray-10 { background-color: hsl(0, 0%, 10%); }
.bgc-gray-20 { background-color: hsl(0, 0%, 20%); }
.bgc-gray-30 { background-color: hsl(0, 0%, 30%); }
.bgc-gray-40 { background-color: hsl(0, 0%, 40%); }
.bgc-gray-50 { background-color: hsl(0, 0%, 50%); }
.bgc-gray-60 { background-color: hsl(0, 0%, 60%); }
.bgc-gray-70 { background-color: hsl(0, 0%, 70%); }
.bgc-gray-80 { background-color: hsl(0, 0%, 80%); }
.bgc-gray-90 { background-color: hsl(0, 0%, 90%); }
.bgc-gray-95 { background-color: hsl(0, 0%, 95%); }

.bgc-white { background-color: white; }
.bgc-gray { background-color: gray; } 
.bgc-black { background-color: black; }

/* fg */
.fgc-y, 	.fgc-yellow 		{ color: #FFDE17; } 	/* Gul */
.fgc-yo, 	.fgc-yellow-orange 	{ color: #F9A61C; } 	/* Gul-Orange */
.fgc-o, 	.fgc-orange 		{ color: #F68121; } 	/* Orange */
.fgc-or, 	.fgc-orange-red 	{ color: #EE3E3E; } 	/* Orange-Rød */
.fgc-r, 	.fgc-red 			{ color: #ED1C24; } 	/* Rød */
.fgc-rp, 	.fgc-red-purple 	{ color: #A3248F; } 	/* Rød-Lilla */
.fgc-p, 	.fgc-purple 		{ color: #5C2E91; } 	/* Lilla */
.fgc-pb, 	.fgc-purple-blue 	{ color: #21409B; } 	/* Lilla-Blå */
.fgc-b, 	.fgc-blue 			{ color: #0166B4; } 	/* Blå */
.fgc-bg, 	.fgc-blue-green 	{ color: #01AAAD; } 	/* Blå-Grønn */
.fgc-g, 	.fgc-green 			{ color: #00A560; } 	/* Grønn */
.fgc-gy, 	.fgc-green-yellow 	{ color: #70BE44; } 	/* Grønn-Gul */

a {
  &.fgc-y, 	&.fgc-yellow 		{ color: #FFDE17; } 	/* Gul */
  &.fgc-yo, 	&.fgc-yellow-orange 	{ color: #F9A61C; } 	/* Gul-Orange */
  &.fgc-o, 	&.fgc-orange 		{ color: #F68121; } 	/* Orange */
  &.fgc-or, 	&.fgc-orange-red 	{ color: #EE3E3E; } 	/* Orange-Rød */
  &.fgc-r, 	&.fgc-red 			{ color: #ED1C24; } 	/* Rød */
  &.fgc-rp, 	&.fgc-red-purple 	{ color: #A3248F; } 	/* Rød-Lilla */
  &.fgc-p, 	&.fgc-purple 		{ color: #5C2E91; } 	/* Lilla */
  &.fgc-pb, 	&.fgc-purple-blue 	{ color: #21409B; } 	/* Lilla-Blå */
  &.fgc-b, 	&.fgc-blue 			{ color: #0166B4; } 	/* Blå */
  &.fgc-bg, 	&.fgc-blue-green 	{ color: #01AAAD; } 	/* Blå-Grønn */
  &.fgc-g, 	&.fgc-green 			{ color: #00A560; } 	/* Grønn */
  &.fgc-gy, 	&.fgc-green-yellow 	{ color: #70BE44; } 	/* Grønn-Gul */
  
}

.fgc-gray-10 { color: hsl(0, 0%, 10%); }
.fgc-gray-20 { color: hsl(0, 0%, 20%); }
.fgc-gray-30 { color: hsl(0, 0%, 30%); }
.fgc-gray-40 { color: hsl(0, 0%, 40%); }
.fgc-gray-50 { color: hsl(0, 0%, 50%); }
.fgc-gray-60 { color: hsl(0, 0%, 60%); }
.fgc-gray-70 { color: hsl(0, 0%, 70%); }
.fgc-gray-80 { color: hsl(0, 0%, 80%); }
.fgc-gray-90 { color: hsl(0, 0%, 90%); }
.fgc-gray-95 { color: hsl(0, 0%, 95%); }

.fgc-white { color: white; }
.fgc-gray { color: gray; } 
.fgc-black { color: black; }





/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}


@media (max-width: 575px) {  } // xs
@media (min-width: 576px) {  } // sm
@media (min-width: 768px) {  } // md
@media (min-width: 992px) {  } // lg
@media (min-width: 1200px) {  } // xl
@media (min-width: 1600px) {  } // xxl
