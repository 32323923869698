/* You can add global styles to this file, and also import other style files */

:root {
  --app-fallback-color-primary: #444444;
  --app-fallback-color-primary-darken: darken(#444444, 20);
  --app-fallback-color-primary-lighten: lighten(#444444, 20);

  --app-fallback-color-secondary: inherit;
  --app-fallback-color-bg-primary: var(--app-fallback-color-primary);
  --app-fallback-color-bg-secondary: var(--app-fallback-color-secondary);
  --app-fallback-color-text-primary: rgba(0,0,0,.85);
 
  // --app-color-primary-langedrag: #9c3359;

  --app-color-primary     : var(--app-custom-color-primary,       var(--app-theme-color-primary,      --app-fallback-color-primary      ));
  --app-color-primary-darken     : var(--app-custom-color-primary-darken,       var(--app-theme-color-primary-darken,      --app-fallback-color-primary-darken      ));
  --app-color-primary-lighten     : var(--app-custom-color-primary-lighten,       var(--app-theme-color-primary-lighten,      --app-fallback-color-primary-lighten      ));
  
  --app-color-secondary   : var(--app-custom-color-secondary,     var(--app-theme-color-secondary,    --app-fallback-color-secondary    ));
  --app-color-bg-primary  : var(--app-custom-color-bg-primary,    var(--app-theme-color-bg-primary,   --app-fallback-color-bg-primary   ));
  --app-color-bg-secondary: var(--app-custom-color-bg-secondary,  var(--app-theme-color-bg-secondary, --app-fallback-color-bg-secondary ));
  --app-color-text-primary: var(--app-custom-color-text-primary,  var(--app-theme-color-text-primary, --app-fallback-color-text-primary ));   
   
}

// $mbsc-ios-accent: var(--app-color-primary);
// $mbsc-material-accent: var(--app-color-primary);
// $mbsc-mobiscroll-accent: var(--app-color-primary);

// @import "~@mobiscroll/angular/dist/css/mobiscroll.scss";



// //@import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.ql-lang-no {
  .ql-snow {
    .ql-tooltip::before {
      content: "Besøk URL:";
    }
     .ql-tooltip[data-mode="link"]::before {
      content: "Skriv inn link:";
    }

    .ql-tooltip.ql-editing a.ql-action::after {

      content: 'Lagre';
    }

    .ql-tooltip a.ql-action::after {
      content: 'Rediger';
    }

    .ql-tooltip a.ql-remove::before {
      content: 'Fjern link';
    }


    .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
      content: 'Overskrift 1';
    }

    .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
      content: 'Overskrift 2';
    }
    .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
      content: 'Overskrift 3';
    }
    .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
      content: 'Overskrift 4  ';
    }

  }

}



@import "styles-tailwind-light.scss";

@import "styles-lib.scss";
@import "styles-custom.scss";
@import 'styles-widget.scss';

body{
  height: auto;
}





