/* You can add global styles to this file, and also import other style files */


.text-center { text-align: center; }
.text-right {  text-align: right; }
.text-left {  text-align: left; }

.ant-btn.text-center { text-align: center; }
.ant-btn.text-right {  text-align: right; }
.ant-btn.text-left {  text-align: left; }



.pull-left { float: left; }
.pull-right { float: right; }
.pull-clear { clear: both; }



.w-100 {  width: 100%; }
.w-auto {  width: auto; }

.text-xs {  font-size: 0.6rem; }
.text-sm {  font-size: 0.8rem; }
.text-md {  font-size: 1.0rem; }
.text-lg {  font-size: 1.5rem; }
.text-xl {  font-size: 2.0rem; }

.text-bold, a.text-bold {  font-weight: bold; }
.text-italic {  font-style: italic; }
.text-norwap { white-space: nowrap; }

.cursor-pointer { cursor: pointer;  }

.text-ellipsis { 
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


.p-d { padding: 0.75rem;  }  // default 
.p-1 { padding: 0.25rem; }
.p-2 { padding: 0.5rem; }
.p-3 { padding: 0.75rem; }
.p-4 { padding: 1rem; }
.p-8 { padding: 2rem; }
.p-12 { padding: 3rem; }

.m-d { margin: 0.75rem;  }  // default 
.m-0 { margin: 0; }
.m-1 { margin: 0.25rem; }
.m-2 { margin: 0.5rem; }
.m-3 { margin: 0.75rem; }
.m-4 { margin: 1rem; }
.m-8 { margin: 2rem; }
.m-12 { margin: 3rem; }

.my-d	{ margin-top: 0.75rem; margin-bottom: 0.75rem; }
.my-1	{ margin-top: 0.25rem; margin-bottom: 0.25rem; }

.mx-d	{ margin-left: 0.75rem; margin-right: 0.75rem; }
.mx-1	{ margin-left: 0.25rem; margin-right: 0.25rem; }

.mt-d {  margin-top: 0.75rem; }
.mt-1 {  margin-top: 0.25rem; }
.mt-5 {  margin-top: 1.25rem; }

.mb-d {  margin-bottom: 0.75rem; }
.mb-1 {  margin-bottom: 0.25rem; }
.mb-5 {  margin-bottom: 1.25rem; }

.ml-d {  margin-left: 0.75rem; }
.ml-1 {  margin-left: 0.25rem; }
.ml-5 {  margin-left: 1.25rem; }

.mr-d {  margin-right: 0.75rem; }
.mr-1 {  margin-right: 0.25rem; }
.mr-5 {  margin-right: 1.25rem; }


.block { display: block; }	
.inline-block { display: inline-block; }	
.inline { display: inline; }	
.hidden { display: none; }


.visible { visibility: visible; }
.invisible { visibility: hidden; }


// 

@media (min-width: 576px) { 
  .sm\:block { display: block; }	
  .sm\:inline-block { display: inline-block; }	
  .sm\:inline { display: inline; }	
  .sm\:hidden { display: none; }

  .sm\:visible { visibility: visible; }
  .sm\:invisible { visibility: hidden; }

  .sm\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }

  .sm\:m-0 { margin: 0; }
  
  .sm\:p-12 { padding: 3rem; }
}
@media (min-width: 768px) { 
  .md\:block { display: block; }	
  .md\:inline-block { display: inline-block; }	
  .md\:inline { display: inline; }
  .md\:hidden { display: none; }	

  .md\:visible { visibility: visible; }
  .md\:invisible { visibility: hidden; }
  
  .md\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }
  .md\:m-0 { margin: 0; }
  .md\:p-12 { padding: 3rem; }
}
@media (min-width: 992px) { 
  .lg\:block { display: block; }	
  .lg\:inline-block { display: inline-block; }	
  .lg\:inline { display: inline; }	
  .lg\:hidden { display: none; }

  .lg\:visible { visibility: visible; }
  .lg\:invisible { visibility: hidden; }
  
  .lg\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }
  .lg\:m-0 { margin: 0; }
  .lg\:p-12 { padding: 3rem; }
}
@media (min-width: 1200px) { 
  .xl\:block { display: block; }	
  .xl\:inline-block { display: inline-block; }	
  .xl\:inline { display: inline; }	
  .xl\:.hidden { display: none; }

  .xl\:visible { visibility: visible; }
  .xl\:invisible { visibility: hidden; }
  
  .xl\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }
  .xl\:m-0 { margin: 0; }
  .xl\:p-12 { padding: 3rem; }
}
@media (min-width: 1600px) { 
  .xxl\:block { display: block; }	
  .xxl\:inline-block { display: inline-block; }	
  .xxl\:inline { display: inline; }	
  .xxl\:.hidden { display: none; }

  .xxl\:visible { visibility: visible; }
  .xxl\:invisible { visibility: hidden; }
  
  .xxl\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }
  .xxl\:m-0 { margin: 0; }
  .xxl\:p-12 { padding: 3rem; }
}






