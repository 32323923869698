/* */


.cbapi-iframe {
  body {
    background-color: transparent;
  }
  .cdk-overlay-container {
    // height: auto;
  }
}


.product-grid {
  .cell {
    border: 1px solid #f0f0f0;
    padding: 3px;
    
  
    .cell-img {
      display: block;
      height: 175px;
      background-color: #fff; /* Used if the image is unavailable */
      background-position: center center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
    }
    .header {
      font-size: 18px;
      margin-top: 10px;
    }
    .lead {
      height: auto;
      overflow-y: auto; 
      line-height: 1.4em;
    }
  }


  

  @media (min-width: 576px) {

    .cell {
      .lead {
        height: 60px; 
      }
    }

  }


}




